<template>
  <b-modal :size="mode === 'other' ? 'auto' : 'lg'" v-model="show" :title="title">
    <div class="moder-review-solution-popup">
      <b-form-checkbox v-model="accept" v-if="mode === 'other'" class="mb-3">
        Вы одобрите отзыв и можете выбрать дополнительную опцию ниже:
      </b-form-checkbox>
      <b-row>
        <b-col md="5" sm="12" class="mb-4" v-if="mode === 'cancel'">
          <b-form-group>
            <b-form-checkbox-group
              :options="refuseReasons"
              value-field="id"
              text-field="title"
              v-model="selectedReasons"
            />
          </b-form-group>
          <b-form-textarea
            v-model="comment"
            class="input"
            placeholder="Комментарий к причине отклонения"
            rows="3"
            max-rows="6"
          ></b-form-textarea>
        </b-col>
        <b-col md="7" sm="12">
          <b-row class="mb-3">
            <b-col>
              <h6 class="h6 text-secondary">Взять на карандаш</h6>
              <b-form-checkbox v-model="mark.advertiser"> Кто рекламировал </b-form-checkbox>
              <b-form-checkbox v-model="mark.customer"> Мой аккаунт </b-form-checkbox>
            </b-col>
          </b-row>
          <b-row class="mb-3">
            <b-col>
              <h6 class="h6 text-secondary">Снять балл</h6>
              <b-form-checkbox v-model="penalty.customer">
                С моего аккаунта (сейчас {{ advertiser_penalty }} балла)
              </b-form-checkbox>
              <b-form-checkbox v-model="penalty.advertiser">
                С того, кто рекламировал (сейчас {{ customer_penalty }} балла)
              </b-form-checkbox>
            </b-col>
          </b-row>
          <b-row class="mb-3">
            <b-col>
              <h6 class="h6 text-secondary">Отправить в черный список</h6>
              <b-form-checkbox v-model="block.advertiser">
                Того, кто рекламировал. Выход через 10 отзывов
              </b-form-checkbox>
              <b-form-checkbox v-model="block.advertiser_forever">
                Того, кто рекламировал. Навсегда
              </b-form-checkbox>
              <b-form-checkbox v-model="block.customer"> Мой аккаунт </b-form-checkbox>
              <b-form-checkbox v-model="block.user"> Пользователь </b-form-checkbox>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </div>

    <template #modal-footer>
      <div class="w-100">
        <b-button variant="outline-secondary" @click="hide()" class="mr-3"> Отмена </b-button>
        <b-button variant="yellow" @click="confirm()">Применить</b-button>
      </div>
    </template>
  </b-modal>
</template>

<script>
import { mapState, mapMutations } from 'vuex';
import moderApi from '../../api/reviews-moderation';
import EventBus from '../../event-bus';

export default {
  data: () => ({
    accept: true, // Работает только когда mode == 'other'
    comment: '',
    selectedReasons: [],
    mark: { advertiser: false, customer: false },
    penalty: { customer: false, advertiser: false },
    block: { advertiser: false, advertiser_forever: false, customer: false, user: false },
    advertiser_penalty: 3,
    customer_penalty: 3,
  }),
  computed: {
    ...mapState({
      refuseReasons: (state) => state.reviewsModeration.refuseReasons,
      review: (state) => state.reviewsModeration.currentSolutionReview,
      mode: (state) => state.reviewsModeration.solutionPopupMode,
    }),
    show: {
      get() {
        return this.$store.state.reviewsModeration.showSolutionPopup;
      },
      set() {
        this.hide();
      },
    },
    title() {
      let t;
      if (this.mode === 'other') t = 'Одобрить и ...';
      else if (this.mode === 'cancel') t = 'Отказ';
      return t;
    },
  },
  methods: {
    ...mapMutations('reviewsModeration', ['hideSolutionPopup']),

    reset() {
      this.$set(this, 'selectedReasons', []);
      this.comment = '';
      this.accept = true;
    },
    hide() {
      this.reset();
      this.hideSolutionPopup();
    },
    confirm() {
      const params = {};
      if (this.block.advertiser || this.penalty.advertiser || this.block.advertiser_forever)
        moderApi.ban(
          this.review.advertiser,
          null,
          this.penalty_advertiser,
          this.block_advertiser,
          this.block_advertiser_forever
        );
      if (this.penalty.customer || this.block.customer)
        moderApi.ban(this.review.customer, null, this.penalty_customer, this.block_customer);
      if (this.block.user)
        moderApi.ban(null, this.review.user_id, 0, true, this.block_advertiser_forever);

      if (this.mark_advertiser) moderApi.mark(this.review.advertiser);
      if (this.mark_customer) moderApi.mark(this.review.customer);

      const reviewAccepted = this.mode === 'other' ? this.accept : false;
      moderApi.update({
        ...this.review,
        id: this.review.id,
        processed: true,
        penalty: this.penalty_customer || this.penalty_advertiser,
        accepted: reviewAccepted,
        banned: !this.accepted && (this.block_advertiser || this.block_customer || this.block_user),
        reasons_comment: this.comment,
        refuse_reasons: this.selectedReasons,
      });
      this.hide();
      this.reset();
      EventBus.$emit(`processed-review-${this.review.id}`, reviewAccepted);
    },
  },
};
</script>

<style scoped>
@media (min-width: 768px) {
  .moder-review-solution-popup >>> .custom-control-label {
    white-space: nowrap;
  }
}
</style>
