<template>
  <div class="moder-review-right">
    <div class="moder-review-right-container">
      <template>
        <div class="text color-red" v-if="advertiserBans.length">
          Исполнитель в ЧС {{ advertiserBannedForever ? 'навсегда' : 'временно' }}
          <span v-if="!advertiserBannedForever">
            (сейчас подряд {{ review.positive_reviews_in_row }} отзывов)
          </span>
        </div>
        <div class="text color-red" v-if="customerBans.length">
          {{
            customerBans.filter((val) => val.instaname === review.customer).length
              ? 'Заказчик в ЧС'
              : `В чс есть похожие на заказчика ники: (${customerBans
                  .map((val) => val.instaname)
                  .join(', ')})`
          }}
        </div>
        <div class="text color-red" v-if="userBans.length">Пользователь в ЧС</div>
        <template v-if="advertiserNameMatches.length">
          <span class="text color-red">Похожие ники исполнителей из ЧС: </span>
          <span class="text">({{ advertiserNameMatches.join(', ') }})</span>
        </template>
      </template>
      <div class="text" v-if="!review.largest_reach">Охваты из рекламы не заполнены</div>
      <div
        class="text"
        v-if="
          (review.largest_reach > review.coverage_avg * 4 ||
            review.largest_reach < review.coverage_avg / 4) &&
          review.largest_reach
        "
      >
        Охваты блогера в течениe 2 месяцев отличаются в 4 раза в большую или меньшую сторону
      </div>
      <div
        class="text"
        v-if="
          (review.largest_reach < review.price || review.largest_reach > review.price * 15) &&
          review.largest_reach
        "
      >
        Соотношение цены за рекламу к охватам меньше чем 1:1 или больше чем 1:15
      </div>
      <div class="text">
        <a
          @click.prevent="openReviewsModal('Отзывы исполнителя', { executor: review.advertiser })"
          class="link"
          target="_blank"
          >Отзывы на того, кто рекламировал</a
        >
        <br />
        <a
          @click.prevent="openReviewsModal('Отзывы заказчика', { executor: review.customer })"
          class="link"
          target="_blank"
          >Отзывы на того, кто заказал</a
        >
        <br />
        <a
          v-if="review.user"
          @click.prevent="openReviewsModal('Отзывы автора', { user: review.user })"
          class="link"
          target="_blank"
          >Все отзывы автора</a
        >
      </div>
      <div class="text" v-if="review.message_links">
        <a
          :href="link"
          class="link"
          target="_blank"
          :key="link"
          v-for="link in review.message_links"
        >
          {{ link }}
        </a>
      </div>
      <div class="text" v-if="review.marked_doubt.advertiser.length > 0">
        Рекламодатель на карандаше у
        <span :key="k" v-for="k in review.marked_doubt.advertiser">{{ k }}, </span>
      </div>
      <div class="text" v-if="review.marked_doubt.customer.length > 0">
        Заказчик на карандаше у
        <span :key="k" v-for="k in review.marked_doubt.customer">{{ k }}, </span>
      </div>
      <div class="text" v-if="review.from_user.length > 1">
        <a
          href="#"
          class="link"
          @click.prevent="
            openReviewsModal('Повторные отзывы на исполнителя', { id: review.from_user })
          "
        >
          Повторные отзывы на исполнителя:
          <span :key="k" v-for="k in review.from_user">{{ k }}, </span>
        </a>
      </div>
      <div
        class="text"
        v-if="review.price_per_one < 0.3 && !review.price_meta && review.arrival && review.price"
      >
        Слишком маленькая цена за подписчика
      </div>
      <div class="text">
        <span v-if="review.user_info.accepted > 0"
          >Одобрено {{ review.user_info.accepted }} |
        </span>
        <span v-if="review.user_info.canceled > 0">Отказов {{ review.user_info.canceled }} | </span>
        <span v-if="review.user_info.bans > 0">Был в ЧС {{ review.user_info.bans }} | </span>
      </div>
      <div class="text" v-if="review.reasons_comment" @click="showRefuseComments">
        <a class="link">{{ showComments ? 'Скрыть' : 'Показать' }} комментарии по отказам</a>
        <p class="refuse_comment" v-if="showComments">{{ review.reasons_comment }}</p>
      </div>
      <div class="text" v-if="review.user_info.refuse_reasons.length > 0">
        Причины отказов:
        <span>
          {{
            refuseReasons
              .filter((reason) =>
                review.user_info.refuse_reasons.map((info) => info[1]).includes(reason.id)
              )
              .map((val) => val.title)
              .join(', ')
          }}
        </span>
      </div>
      <div class="text" v-if="review.reciprocal_reviews.length > 0">
        <a
          href="#"
          class="link"
          @click.prevent="openReviewsModal('Взаимные отзывы', { id: review.reciprocal_reviews })"
        >
          Взаимные отзывы x{{ review.reciprocal_reviews.length / 2 }}
        </a>
      </div>

      <div class="text" v-if="review.equal_text.length > 0">
        <a
          href="#"
          class="link"
          @click.prevent="openReviewsModal('Текст идентичен', { id: review.equal_text })"
        >
          Текст идентичен x{{ review.equal_text.length }}, был в
          <span :key="k" v-for="k in review.equal_text">#{{ k }}, </span>
        </a>
      </div>

      <div class="text" v-if="review.equal_ip.length > 3">
        <a
          href="#"
          class="link"
          @click.prevent="openReviewsModal('Подозрение на накрутку', { id: review.equal_ip })"
        >
          Подозрение на накрутку, проверьте
          <span :key="k" v-for="k in review.equal_ip">#{{ k }}, </span>
        </a>
      </div>

      <div class="text" v-if="review.duplicates.length > 0">
        <a
          href="#"
          class="link"
          @click.prevent="openReviewsModal('Дубликат', { id: review.duplicates })"
        >
          Дубликат <span :key="k" v-for="k in review.duplicates">#{{ k }}, </span>
        </a>
      </div>

      <div class="text" v-if="review.suspicious_activity.length > 3">
        <a
          href="#"
          class="link"
          @click.prevent="
            openReviewsModal('Подозрительная активность', { id: review.suspicious_activity })
          "
        >
          Подозрительная активность
        </a>
      </div>

      <div class="text" v-if="review.equal_screenshots.length > 0">
        <a
          href="#"
          class="link"
          @click.prevent="openReviewsModal('Старые скриншоты', { id: review.equal_screenshots })"
        >
          Старые скриншоты x{{ review.equal_screenshots.length }}
        </a>
      </div>

      <template v-if="!isMobile">
        <img
          class="moder-review-summary-image"
          :src="screen.image"
          :key="screen.id"
          v-for="screen in review.screenshots"
        />
      </template>
    </div>
  </div>
</template>

<script>
import { isMobile } from 'mobile-device-detect';
import { mapState, mapMutations, mapActions } from 'vuex';

export default {
  props: ['review'],
  computed: {
    ...mapState({
      refuseReasons: (state) => state.reviewsModeration.refuseReasons,
    }),
    advertiserBans() {
      return this.review.blacklisted_matches_advertiser.filter(
        (val) => val.instaname === this.review.advertiser
      );
    },
    advertiserBannedForever() {
      return this.advertiserBans.filter((val) => val.forever).length;
    },
    customerBans() {
      return this.review.blacklisted_matches_customer.filter(
        (val) => val.instaname === this.review.customer
      );
    },
    userBans() {
      return this.review.blacklisted_matches_user;
    },
    advertiserNameMatches() {
      return this.review.blacklisted_matches_advertiser
        .map((val) => val.instaname)
        .filter((val) => val !== this.review.adveriser);
    },
  },
  data: () => ({
    showComments: false,
    isMobile,
  }),
  methods: {
    ...mapMutations('reviewsModeration', [
      'showReviewsPopup',
      'setReviewsPopupTitle',
      'setReviewPopupList',
    ]),
    ...mapActions('reviewsModeration', ['loadReviewsPopupList']),
    async openReviewsModal(title, payload) {
      this.setReviewsPopupTitle(title);
      this.showReviewsPopup();
      // this.setReviewsPopupList([]);
      await this.loadReviewsPopupList(payload);
    },
  },
};
</script>

<style scoped>
img.moder-review-summary-image {
  max-width: 100%;
  width: 300px;
  margin: 20px 10px;
}
@media (min-width: 768px) {
  .moder-review-right {
    background: #ffffff;
    border: 1px solid #d5dfe4;
    overflow: auto;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    margin: 55px 20px;
    width: 100%;
    min-height: 300px;
    max-height: 500px;
    border-radius: 5px;
  }

  .moder-review-right-container {
    padding: 10px;
    width: 100%;
  }

  .moder-review-right-container-content {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }

  .moder-review-right-container-content > .link:nth-child(1) {
    font-size: 1.4em;
  }

  .moder-review-right-container-content .text:not(:first-child) {
    margin-top: 14px;
  }

  .moder-review-right-screen {
    width: 460px;
    height: 450px;
    margin-top: 23px;
  }

  .moder-review-right .text,
  .moder-review-right .text .link {
    font-size: 20px;
  }
}

@media (min-width: 620px) and (max-width: 1149px) {
  .moder-review-right .text,
  .moder-review-right .text .link {
    color: black;
    font-size: 14px;
  }
}

@media (max-width: 768px) {
  .moder-review-right-container-content {
    display: none;
  }

  .moder-review-right-container-content .text .link {
    font-size: 1em;
  }

  .moder-review-right-container-content .text {
    margin-top: 15px;
  }

  .moder-review-right {
    background: rgb(248, 248, 248);
    border-radius: 5px;
    padding: 10px 5px;
  }

  .moder-review-right .text:not(:last-child) {
    margin-bottom: 10px;
  }
}
.color-red {
  color: red;
}
</style>
